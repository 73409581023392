import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import i18n from 'i18next';

import { T, t, tm, } from '@web-solutions/module-localization';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';

import { getButtonTitleByConfig } from 'core/utils/button-configurator';
import { ButtonPlacementType, COUNTRY_VAT } from 'core/payment/constants';

import { ProductDetails } from 'core/store/billing/selectors';

//@ts-ignore
import { SUPPORT_EMAIL } from 'src/constants/general';

import { ReactComponent as Wallet } from '../../base/icons/wallet.svg';
import { ReactComponent as Dollar } from '../../base/icons/dollar.svg';
import { ReactComponent as Message } from '../../base/icons/message.svg';
import { ReactComponent as Receipt } from '../../base/icons/receipt.svg';
import { getActualPrices } from '../../base/product-info/components/simple-view/utilts';

import { Terms } from './components/terms';
import { ContactUs } from './components/contact-us';

import classes from './style.module.scss';

const subjectText = 'Landing payment description';

interface Props {
  activeProduct: ProductDetails;
  isShort?: boolean;
  className?: string;
}

const PaymentDescription: React.FC<Props> = ({
  className,
  activeProduct,
  isShort,
}) => {
  const {
    name,
    expireDate,
    introExpireDate,
    weekPrice,
    isTrial,
    trialDays,
    trialPrice: productTrialPrice,
    isIntroductory,
    period,
    currency,
  } = activeProduct;

  const countryCode = useSelector((state: any) => state?.billing?.countryCode)

  const { policyLinks, paymentDescriptionType, displayVAT, buttonsConfigs, totalPriceType } = useSelector(remoteConfigSelector);
  const lang = i18n.language

  const { price, descPrice, descPriceTrial } = getActualPrices({ activeProduct, totalPriceType })

  const displayTaxes = displayVAT && !!COUNTRY_VAT[countryCode];

  const priceString = displayTaxes ? descPrice : price;

  const trialPrice = displayTaxes ? descPriceTrial : productTrialPrice;

  const todayPrice = !isTrial ? priceString : trialPrice;

  const buttonConfig = buttonsConfigs[ButtonPlacementType.PAYMENT];
  const btnTitle = getButtonTitleByConfig(buttonConfig, 'core.payment_popup.form.button_title', activeProduct);

  const baseKey = paymentDescriptionType === 'old' ? 'v_first' : 'v_second';

  let tKey = !isShort ? `core.${baseKey}.payment_description` : `core.${baseKey}.payment_description_short`;

  if (isTrial && !isIntroductory) {
    tKey = `${tKey}_trial`;
  }

  if (isIntroductory) {
    tKey = `${tKey}_intr`;
  }

  return (
    <div className={classNames(classes.root, { [classes.short]: isShort }, 'description_light', className)}>
      {paymentDescriptionType !== 'icons' || lang !== 'en' || isShort ?
        <>
          <T
            k={tKey}
            tOptions={{
              plan: tm(name, ''),
              expireDate,
              introExpireDate,
              priceText: priceString,
              weekPrice,
              todayPrice,
              trialDay: trialDays,
              period: t(`core.payment_popup.price_texts.${period}`),
              btnTitle,
            }}
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={{ mblink: <a href={policyLinks.moneyBack} /> }}
          />
          <span> </span>
          {isShort
            && (
              <span className={classes.more}>
                <T
                  k={`core.${baseKey}.payment_description_short_more`}
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  components={{ mblink: <a href={policyLinks.moneyBack} /> }}
                />
              </span>
            )}
          <ContactUs
            text={<T k={`core.${baseKey}.payment_description_contact_us`} />}
            email={SUPPORT_EMAIL ?? ''}
            subjectText={subjectText}
          />
          {policyLinks.descriptor && (
            <>
              <span> </span>
              <T
                k={`core.${baseKey}.payment_description_bank_statement`}
                tOptions={{
                  descriptor: policyLinks.descriptor,
                }}
              />
            </>
          )}
          {policyLinks.phone && (
            <>
              <span> </span>
              <T
                k={`core.${baseKey}.payment_description_phone`}
              />
              <span> </span>
              <a
                href={`tel:${policyLinks.phone}`}
                className={classes.link}
              >
                {policyLinks.phone}.
              </a>
            </>
          )}
          {isShort
            && (
              <Terms text={<T k={`core.${baseKey}.payment_description_short_link`} />} hrefTerms={policyLinks.billingTerms} />
            )}
        </>
        :
        <>
          <div>
            <div className={classes.terms_icon_wrap}>
              <Receipt />
              <p className={classes.terms}>
                <T k={`core.intro_payment_descriptor.transaction`} />
              </p>
            </div>
            <div className={classes.terms_icon_wrap}>
              <Dollar />
              <p className={classes.terms}>
                <T k={`core.intro_payment_descriptor.payments`} tOptions={{ currency }} />
              </p>
            </div>
            <div className={classes.terms_icon_wrap}>
              <Wallet />
              <p className={classes.terms}>
                <T
                  k={`core.intro_payment_descriptor.${isTrial ? 'intro' : 'subscription'}`}
                  tOptions={{
                    plan: tm(name, ''),
                    period: t(`core.payment_popup.price_texts.${period}`),
                    todayPrice,
                    expireDate,
                    priceText: price,
                  }}
                />
              </p>
            </div>
            <div className={classes.terms_icon_wrap}>
              <Message />
              <p className={classes.terms}>
                <ContactUs
                  text={<T k={`core.${baseKey}.payment_description_contact_us`} />}
                  email={SUPPORT_EMAIL ?? ''}
                  subjectText={subjectText}
                />
              </p>
            </div>
          </div>

          <div className={classes.descriptor}>
            {policyLinks.descriptor &&
              <T k={`core.intro_payment_descriptor.descriptor`} tOptions={{
                descriptor: policyLinks.descriptor,
              }} />
            }
            <br />
            {policyLinks.phone &&
              <>
                <T k={`core.intro_payment_descriptor.descriptor_phone`} />
                {policyLinks.phone}.
              </>
            }
          </div>
        </>
      }
    </div>
  );
};

export default PaymentDescription;
